
.manifest-marker {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.3s ease-out;
  width: auto;
  height: auto;
}

.point-number {
  color: var(--color-neutral);
  font-weight: 600;
  margin-top: -8px;
}

.details {
  color: var(--color-neutral-80);
  font-size: 12px;
  width: 350px;
  background-color: var(--color-white);
}

.details .route-info {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid var(--color-neutral-20);
}

.details .box-name {
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.details .box-name .dot-color{
  width: 10px;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 8px;
}

.details .box-name .status-label{
  line-height: 22px;
  border-radius: 11px;
  padding: 0 10px;
  margin-left: 8px;
}

.details .box-name .box-position {
  margin-top: -3px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  color: var(--color-white);
}
.details .outTimeLabel {
  color: var(--color-yellow-dark);
  font-weight: 500;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  background-color: var(--color-yellow-dark-light);
  border-radius: 25px;
  margin-left: 4px;
}
.details .outTimeLabel img {
  margin-right: 4px;
}

.lineWrap {
  display: flex;
  margin-bottom: 10px;
}
.iconLine {
  width: 26px;
  text-align: center;
}

.iconLine img{
  width: 14px;
}

.textLine {
  flex: 1;
}
.textLine span {
  color: var(--color-neutral-50);
}