@tailwind base;
@tailwind components;

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

:root {
  --color-body-bg: #f4f7fc;
  --color-white: #fff;
  --color-black: #000;

  --color-red: #E40505;
  --color-red-dark: #ac0000;
  --color-red-light: #FFEEEE;

  --color-green: #29BA74;
  --color-green-dark: #176d43;
  --color-green-light: #D7F4E6;

  --color-yellow-dark: #F68A0E;
  --color-yellow-dark-light: #FFF1D6;
  --color-yellow: #FFBB33;
  --color-yellow-light: #FFF8EB;

  --color-blue-dark: #0F5584;
  --color-blue-dark-50: #0099CC;
  --color-blue-dark-5: #E6F2FF;

  --color-blue: #136BA6;
  --color-blue-light: #CCE4FF;
  --color-blue-90: #2B7AAF;
  --color-blue-80: #4289B8;
  --color-blue-70: #5A97C1;
  --color-blue-60: #71A6CA;
  --color-blue-50: #89B5D2;
  --color-blue-40: #A1C4DB;
  --color-blue-30: #B8D3E4;
  --color-blue-20: #D0E1ED;
  --color-blue-10: #E7F0F6;
  --color-blue-5: #F3F8FB;

  --color-neutral: #0B111E;
  --color-neutral-90: #0F172A;
  --color-neutral-80: #1E293B;
  --color-neutral-70: #334155;
  --color-neutral-60: #475569;
  --color-neutral-50: #64748B;
  --color-neutral-40: #8B9BB2;
  --color-neutral-30: #BECBDA;
  --color-neutral-20: #E2E8F0;
  --color-neutral-10: #F1F5F9;
  --color-neutral-5: #F8FAFC;

  --color-status-bg-error: var(--color-red-light);
  --color-status-error: var(--color-red);
  --color-status-bg-warning: var(--color-yellow-light);
  --color-status-warning: var(--color-yellow);
  --color-status-bg-success: var(--color-green-light);
  --color-status-success: var(--color-green);
  --color-status-bg-info: var(--color-yellow-dark-light);
  --color-status-info: var(--color-yellow-dark);

  --color-line: #E7ECF3;
}

* {
  outline: none !important;
}

body {
  color: var(--color-neutral-90);
  background-color: var(--color-body-bg);
  font-size: .875rem;
  line-height: 1.375rem;
  font-weight: 400;
}

svg {
  fill: var(--color-neutral-90);
}

@media print {

  /* Make sure browser print with background graphics */
  * {
    print-color-adjust: exact;
  }

  footer {
    page-break-after: always;
  }
}

@media screen {
  .show-only-print {
    display: none
  }
}


@tailwind utilities;

/* custome tooltip */
.__react_component_tooltip.type-error {
  color: var(--color-red);
  background-color: var(--color-red-light);
  border: 1px solid var(--color-red);
  font-weight: 600;
}

.__react_component_tooltip.type-error::after {
  background-color: var(--color-red-light) !important;
  border: 1px solid var(--color-red) !important;
}

/* Map edit icon tooltip */
.icon-changeroute {
  position: relative;
}
.icon-changeroute:hover .tooltip-button{
  opacity: 1;
}
.tooltip-button {
  pointer-events: none;
  position: absolute;
  opacity: 0;
  top: 140%;
  left: 50%;
  margin-left: -48px;
  color: white;
  background-color: var(--color-neutral-60);
  border-radius: 4px;
  padding: 8px 12px;
  white-space: nowrap;
}

.tooltip-button:after {
  content: "";
  display: block;
  position: absolute;
  top: -4px;
  left: 50%;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  background-color: var(--color-neutral-60);
}